<template>
    <el-dialog
        class="editDialog"
        :append-to-body="formConfig.info.fullScreen!='true'"
        :modal="formConfig.info.fullScreen!='true'"
        :fullscreen="formConfig.info.fullScreen=='true'"
        width="70vw"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}{{ formConfig.info.tableLabel||formConfig.info.tableName }}</div>

        <div class="content" v-loading="loading">
            <slot name="form" :disabled="disabled" :form="form" :formConfig="formConfig">
                <Form :disabled="disabled" :form="form" :formConfig="formConfig" />
            </slot>
        </div>

        <div slot="footer" v-show="!loading">
            <slot name="button" :metaDataMap="form" :form="refForm" :options="options"></slot>
            <el-button type="primary" v-if="!disabled" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
        Form: () => import("./Form.vue"),
    },
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
        refresh: {
            type: Function,
            default: () => {}
        },
        editSubmit:{
            type: Function,
            default: () => {}
        },
        editInit:{
            type: Function,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        formConfig: {
            type: Object,
            default:function(){
                return {
                    info:{},
                    list:[],
                }
            }
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            form: {},
            refForm:null,
        };
    },
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showEditDialog = newVal;
                if(newVal){
                    this.init(this.options);
                    this.$nextTick(()=>{
                        this.refForm.clearValidate();
                    });
                }
            }
        },
    },
    provide () {
        return {
            formMunted:this.formMunted,
        }
    },
    created() {},
    mounted(){},
    methods: {
        formMunted(refForm){
            this.refForm = refForm;
        },
        init(options){
            if(options.id){
                this.loading = true;
                this.editInit(options).then(r=>{
                    this.form = r;
                    this.loading = false;
                }).catch(err=>{
                    this.loading = false;
                    console.log(err);
                })
            }else {
                this.form = {};
                this.loading = false;
            }
        },
        submit(){
            this.refForm.validate((valid,obj) => {
                if (valid) {
                    this.submitLoading = true;
                    var metaDataMapId = this.form.id;
                    delete this.form.id;
                    this.editSubmit({
                        metaDataMapId,
                        tableName:this.formConfig.info.tableName,
                        metaDataMap:this.form,
                        options:this.options,
                    }).then( res => {
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");

                    }).catch(err=>{
                        this.submitLoading = false;
                        console.log(err);
                    })
                }else {
                    this.scrollView(obj);
                };
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
</style>
